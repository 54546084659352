/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import '@fontsource/material-icons/index.css';
@import '@fontsource/roboto/index.css';
@import '@fontsource-variable/cairo/index.css';

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import '@ionic/angular/css/ionic-swiper.css';

@import '@uppy/core/dist/style.min.css';
@import '@uppy/dashboard/dist/style.min.css';

.uppy-Dashboard-inner {
  border-radius: 1rem;
  border: solid 4px #e7e7e7;
}
.uppy-DashboardContent-back {
  color: var(--ion-color-black);
}

.uppy-DashboardContent-addMore {
  color: var(--ion-color-black);
}

.uppy-StatusBar.is-waiting {
  .uppy-StatusBar-actionBtn--upload {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);

    &:disabled {
      background-color: var(--ion-color-dark-shade);
    }

    &:hover {
      background-color: darken(#222428, 30%);
    }

    &:active {

    }
  }
}

.uppy-Dashboard-innerWrap {
  border-radius: 1rem;
}

.uppy-Dashboard-AddFiles {
  border-radius: 1rem !important;
  margin: 15px !important;
}
.uppy-DragDrop-label {
  color: #333;
}


@import "./theme/markdown";
@import "./theme/expense-table";
@import "./theme/icons";
@import "./theme/round-cards";

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url('/assets/OpenSans-Regular.ttf')
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url('/assets/OpenSans-Bold.ttf')
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url('/assets/OpenSans-SemiBold.ttf')
}

ion-badge,
ion-button {
  border-radius: 0;
}

ion-title {
  color: var(--ion-color-secondary);
  font-size: 24px;
  font-weight: 700;

  @media only screen and (min-width: 600px) {
    font-size: 36px;
  }
}

.w-75-modal {
  --min-width: 75%;
}

.w-50-modal {
  --min-width: 50%;
}

.h-75-modal {
  --min-height: 75%;
}
.flex-grow {
  flex-grow: 1;
}

.display-flex {
  display: flex;
}
.display-inline {
  display: inline;
}
.display-inline-block {
  display: inline-block;
}
.flex-column {
  flex-direction: column;
}

.padding-bot-10{
  padding-bottom: 10px;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: calc(1/3*100%);
}
.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.fancy-link {
  text-decoration: none;
  color: var(--ion-color-primary);

  &:visited {
    color: var(--ion-color-primary);
  }

  &.inverted {
    color: var(--ion-color-primary-contrast);
    background-image: linear-gradient(var(--ion-color-primary-contrast), var(--ion-color-primary-contrast));

    &:visited {
      color: var(--ion-color-primary-contrast);
    }
  }

  background-image: linear-gradient(var(--ion-color-primary), var(--ion-color-primary));
  background-size: 0 0.1em;
  background-position-y: 100%;
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-size: 100% 0.1em;
  }
}

swiper-container {
  --swiper-theme-color: var(--ion-color-dark);
  --swiper-pagination-bullet-inactive-color: var(#cccccc);
  --swiper-pagination-color: var(--ion-color-dark);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

/**
relationship-mask.component
style for mat component
 */
.mat-mdc-checkbox label{
  font-size: 1rem;
}

.textarea {
  height: 15em;
  .native-wrapper {
    height: 15em;
  }
}

ion-modal {
  ion-header {
    ion-title, ion-toolbar > ion-title {
      font-size: 1.3rem;
    }
  }
}

a {
  color: var(--ion-color-dark);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.header-md {
  box-shadow: none;
}
