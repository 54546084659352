.markdown-content {
  table {
    border-collapse: collapse;
  }

  tr {
    border-bottom: solid 1px black;
  }

  tr:nth-child(odd) td{
    background-color: var(--ion-color-light);
  }

  td, th {
    padding: .5em;
  }
}
