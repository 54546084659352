table.expense-table {
  width: 100%;
  table-layout: auto;
  word-break: break-word;

  thead {
    tr {
      border-bottom: 2px solid rgba(0, 0, 0, 0.4);

      td {
        font-weight: 800;
        word-break: break-word;
        padding: 0.5em;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);

      &:last-child {
        border-bottom: none;
      }

      td {
        word-break: break-word;
        padding: 0.5em;

        &:first-child {
          font-weight: 600;
        }

        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
}
