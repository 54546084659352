/*--------------------------------

Nucleo icon font
Generated using nucleoapp.com

-------------------------------- */

$icon-font-path: "./fonts" !default;

@font-face {
  font-family: 'Nucleo';
  src: url('#{$icon-font-path}/Nucleo.eot');
  src: url('#{$icon-font-path}/Nucleo.eot') format('embedded-opentype'),
       url('#{$icon-font-path}/Nucleo.woff2') format('woff2'),
       url('#{$icon-font-path}/Nucleo.woff') format('woff'),
       url('#{$icon-font-path}/Nucleo.ttf') format('truetype'),
       url('#{$icon-font-path}/Nucleo.svg') format('svg');
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90  {
	transform: rotate(90deg);
}

.icon-rotate-180 {
	transform: rotate(180deg);
}

.icon-rotate-270 {
	transform: rotate(270deg);
}

.icon-flip-y {
	transform: scaleY(-1);
}

.icon-flip-x {
	transform: scaleX(-1);
}

/* icons */
$icons: (
  'doc-folder': 'ea01',
  'file-docx': 'ea02',
  'file-jpg': 'ea03',
  'file-pdf': 'ea04',
  'file-text': 'ea05',
  'file-xlsx': 'ea06',
  'folder-add': 'ea07',
  'folder-add-2': 'ea08',
  'folder-upload': 'ea09',
  'invoice': 'ea0a',
  'round-euro': 'ea0b',
);

@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .icon-#{""+$name}::before {
    content: unicode($code);
  }
}
